<template>
  <div class="row-column-center" >
    <img v-if="success" src="@/assets/Diamond_120x120@3x.png" style="width: 200px;"/>
    <img v-else src="@/assets/fail@3x.png" style="width: 200px;"/>
    <div class="bigtx" v-if="success">
      Payment Successful
    </div>
    <div class="bigtx" style="margin-top: 50px" v-else>
      Payment Failed
    </div>

    <div style="margin-top: 50px;" class="content" v-if="true">

      <div class="row-left-between" style="width: 300px">
        <div>OutTradeNo:</div>
        <div>{{outTradeNo}}</div>
      </div>
    </div>

    <div class="bottom row-center-center">
      <el-button plain v-if="success" style="width: 300px" @click="home">Got it</el-button>
      <div v-else class="row-column-center">
        <el-button plain style="width: 300px" @click="fail">Report this issue</el-button>
        <el-button type="text"  style="width: 300px;color: #999999;margin-top: 20px" @click="home">back to the homepage</el-button>
      </div>

    </div>
  </div>
</template>

<script>


  import qs from 'qs'
export default {
  name: 'ResultView',
  components: {},
  data() {
    return {
      success:true,
      outTradeNo:null,
    }
  },
  methods:{
    fail(){
      window.location.href="https://discord.gg/JUy8xCy6kd";
    },
    home(){
      this.$router.push("/");
    }
  },
  created(){
    window.scrollTo(0,0);
    let query = qs.parse(location.search.substring(1));
    let status = query.status;
    this.outTradeNo = query.outTradeNo
    if(status===1){
      this.success=true;
    }else if(status==='SUCCESS'){
      this.success=true;
    }else{
      this.success=false;
    }
  },
  mounted(){
    this.$nextTick(() => {
    })
  },
}
</script>
<style scoped>

  .bigtx{
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 32px;
    /* identical to box height */
    text-align: center;
    color: #333333;
  }
  .content{
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: rgba(51, 51, 51, 0.5);
  }
  .bottom {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
  }
</style>
