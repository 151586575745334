<template>

  <div class="row-column-center"  v-loading="loading">
    <div class="title">
      <span>Let’s sign you in!</span>
    </div>
    <div class="content">
      <span>In order to protect your account, please sign in before purchasing</span>
    </div>
    <div style="margin-top: 50px">
      <div class="row-left-center margin">
        <img class="image" src="@/assets/Envelope@3x.png"/>
        <el-button type="text">Continue with Email</el-button>
      </div>
      <div class="row-left-center margin">
        <img class="image" src="@/assets/google@3x.png"/>
        <el-button type="text" @click="login">Continue with Google</el-button>
      </div>
      <div class="row-left-center margin">
        <img class="image" src="@/assets/apple@3x.png"/>
        <el-button type="text">Continue with Apple</el-button>
      </div>
      <div class="row-left-center margin" v-if="false">
        <el-button type="text" @click="logout">Logout</el-button>
      </div>
    </div>
    <div class="bottom row-center-center">
      <div class="row-left-center">
        <img  class="bottom_img" src="@/assets/InfoCircle@3x.png">
        <div  class="bottom_tx">Please select the same sign in way as Pinkverse</div>
      </div>
    </div>
  </div>
</template>

<script>

  import axios from 'axios'
  import qs from 'qs'
  import { initializeApp } from "firebase/app";
  import { getAuth, signInWithPopup, GoogleAuthProvider ,signOut} from "firebase/auth";

  const firebaseConfig = {
    apiKey: "AIzaSyChtRsAFDDqpJMQHiSi3qbjymZMCeqGM-E",
    authDomain: "pinkverse-imaginelabs.firebaseapp.com",
    projectId: "pinkverse-imaginelabs",
    storageBucket: "pinkverse-imaginelabs.appspot.com",
    messagingSenderId: "273256060360",
    appId: "1:273256060360:web:565eb37cf12ccfef249cfd",
    measurementId: "G-SNGXKY8JYV"
  };

export default {
  name: 'LoginView',
  components: {},
  data() {
    return {
      user:{
        id:'123213123213',
        name:'Frank Fang',
        avatar:'https://pinkverse.oss-cn-hangzhou.aliyuncs.com/thumb/avatar/Joyce.png',
        diamond:100
      },
      provider:null,
      app:null,
      auth:null,
      loading:false,
      loginInfo:{
        type:2,
        unionid:'109718056052259075483',
        googleEmail:'zaczqs@gmail.com',
        avatar:' ',
        firebaseToken:' ',
        nickname:' ',
        sex:' '
      },
      token:null,
    }
  },
  methods:{
    getUserInfo(){
      console.log('getUserInfo');
      let formData = new FormData()
      formData.append("token", this.token)
      axios({
        url:'http://api.pinkverse.app:7777/apiuser/v1/qingcheng/community/mine/getUserInfo',
        method:'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-requested-with': 'com.liuliurpg.muxi'
        },
      }).then(res=>{
        console.log('getUserInfo res=>',res);
      })
    },
    login(){
      this.loading=true;
      let formData = new FormData()
      formData.append("userInfo", JSON.stringify(this.loginInfo))
      axios({
        url:'http://api.pinkverse.app:7777/apiuser/v1/web/user/login/thirdPartyUserLogin',
        method:'post',
        data:formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
      }).then(res=>{
        console.log('login res=>',res);
        if(res.status===200&&res.data.code==='000000'){
          this.token=res.data.data;
          console.log("token:",this.token)

          this.getUserInfo();
        }else{
          this.$message.error('Login failure');
        }
        this.loading=false;
      })
    },
    logout(){
      signOut(this.auth).then(() => {
        // Sign-out successful.
        console.log("logout")
      }).catch((error) => {
        // An error happened.
      });
    },
    googleSign(){
      console.log("googleSign!!!!!!!!")
      this.loading=true;
      signInWithPopup(this.auth, this.provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          let credential = GoogleAuthProvider.credentialFromResult(result);
          let token = credential.accessToken;
          // The signed-in user info.
          let user = result.user;
          console.log("token",token)
          console.log("user",user)
         // console.log("rawId",user.providerUserInfo[0].rawId)
          console.log("uid",user.providerData[0].uid)
          console.log("email",user.email)
          //
          this.loginInfo.type=2
          this.loginInfo.unionid=user.providerData[0].uid
          this.loginInfo.nickname=user.displayName
          this.loginInfo.avatar=user.photoURL
          this.loginInfo.googleEmail=user.email
          //
          this.login();
        }).catch((error) => {
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        // The email of the user's account used.
        let email = error.customData.email;
        // The AuthCredential type that was used.
        let credential = GoogleAuthProvider.credentialFromError(error);
        // ...

        console.log("errorCode",errorCode)
        console.log("errorMessage",errorMessage)
        console.log("email",email)
        console.log("credential",credential)
        this.loading=false;
      });
    }
  },
  created(){

  },
  mounted(){
    this.$nextTick(() => {
       this.app = initializeApp(firebaseConfig);
      console.log("app:",app)
      this.provider= new GoogleAuthProvider();
      this.provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      this.auth = getAuth();
      console.log("initializeApp")
    })
  },
}
</script>
<style scoped>
  .bottom {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
  }
  .bottom_img{
    width: 20px;
    height: 20px;
  }
  .bottom_tx{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #FF9BC9;
    padding-left: 10px;
  }
  .margin{
    margin-top: 20px;
  }
  .image {
    width: 30px;
    height: 30px;
    margin-right:10px;
  }
  .title{
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    /* identical to box height */
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
  }
  .content{
    text-align: center;
    color: #333333;
    opacity: 0.5;
    padding: 10px;
  }
</style>
