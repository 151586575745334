import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import PurchaseView from '../views/PurchaseView.vue'
import PayerMaxResultView from '../views/PayerMaxResultView.vue'
import PaymentWallResultView from '../views/PaymentWallResultView.vue'
const routes = [
  {
    path: '/',
    name: 'purchase',
    component: PurchaseView
  },
  {
    path: '/callback/payermax',
    name: 'payerMaxCall',
    component: PayerMaxResultView
  },
  {
    path: '/callback/paymentwall',
    name: 'PaymentWallResultView',
    component: PaymentWallResultView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  // {
  //   path: '/purchase',
  //   name: 'purchase',
  //   component: PurchaseView
  // }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

export default router
