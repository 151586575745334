<template>

  <div style="text-align: left">
    <div class="title">
      <span>Get Diamonds</span>
    </div>
    <div class="content">
      <span>Note: buy.pinkverse.app is the only official website for purchasing diamonds</span>
    </div>
    <div class="title-myacount">
      <span>My account</span>
    </div>
    <div class="content" v-if="false">
      <span>Please confirm the account information</span>
    </div>
    <div class="row-left-center" style="margin-top: 10px" v-if="false">
      <img :src="user.avatar" style="width: 64px;height: 64px;border-radius:32px">
      <div style="margin-left: 20px">
        <div style="font-size: 18px;font-weight: 600;height: 25px">{{user.name}}</div>
        <div style="font-size: 12px;color: #000000;opacity: 0.5;line-height: 18px">{{'uid:'}}{{user.id}}</div>
        <div class="row-left-center">
          <img src="@/assets/Diamond@3x.png" style="width: 22px;height: 22px">
          <div style="font-size: 14px;color: #333333;opacity: 0.5;font-weight: bold;">{{user.diamond}}</div>
        </div>
      </div>
    </div>

    <div >
      <el-input v-model="uid" placeholder="Copy UID in the side drawer "></el-input>
    </div>
    <div style="cursor: pointer;margin-top: 5px" @click="dialogVisible=true">
     <span class="content">How to check UID?</span>
    </div>
    <div class="title-myacount" style="margin-top: 30px">
      <span>Purchase Diamonds</span>
    </div>

    <div class="row-left-between" style="margin-top: 10px">
      <div class="purch-body row-center-center"  tabindex="1" @click="paySelect(1)">
        <div>
          <div class="row-left-center">
            <img class="price-icon" src="@/assets/Diamond@3x.png">
            <div class="price-tx">10</div>
          </div>
          <div class="price">$1</div>
        </div>
      </div>
      <div class="purch-body row-center-center" tabindex="5"  @click="paySelect(5)" >
        <div>
          <div class="row-left-center">
            <img class="price-icon" src="@/assets/Diamond@3x.png">
            <div class="price-tx">50</div>
          </div>
          <div class="price">$5</div>
        </div>
      </div>
      <div class="purch-body row-center-center" tabindex="20"  @click="paySelect(20)">
        <div>
          <div class="row-left-center">
            <img class="price-icon" src="@/assets/Diamond@3x.png">
            <div class="price-tx">200</div>
          </div>
          <div class="price">$20</div>
        </div>
      </div>
    </div>

    <div class="row-left-between" style="margin-top: 20px">
      <div class="purch-body row-center-center" tabindex="50" @click="paySelect(50)">
        <div>
          <div class="row-left-center">
            <img class="price-icon" src="@/assets/Diamond@3x.png">
            <div class="price-tx">500</div>
          </div>
          <div class="price">$50</div>
        </div>
      </div>
      <div class="purch-body row-center-center" tabindex="100" @click="paySelect(100)">
        <div>
          <div class="row-left-center">
            <img class="price-icon" src="@/assets/Diamond@3x.png">
            <div class="price-tx">1,000</div>
          </div>
          <div class="price">$100</div>
        </div>
      </div>
      <div class="purch-body row-center-center" tabindex="250"  @click="paySelect(250)">
        <div>
          <div class="row-left-center">
            <img class="price-icon" src="@/assets/Diamond@3x.png">
            <div class="price-tx">2,500</div>
          </div>
          <div class="price">$250</div>
        </div>
      </div>
    </div>


    <div  class="row-center-center" style="margin-top: 50px">
      <el-button v-loading="loading" type="primary" style="border: none;width:50%;background: linear-gradient(91.13deg, #FF83CF 0%, #9C79FF 100%);" @click="createOrder">Purchase</el-button>
    </div>

    <div  class="row-center-center" style="margin-top: 30px">
      <div class="row-left-center">
        <img  class="bottom_img" src="@/assets/InfoCircle@3x.png">
        <div  class="bottom_tx">Announcement</div>
      </div>
    </div>
    <div style="margin-top: 10px">
      <div style="margin-top: 5px" class="row-left">
        <div style="line-height: 30px;" >·</div>
        <div class="content" style="margin-left: 5px">Do not trust any unofficial information posted by other websites, other servers or channels, or other users in the communication group. For example: winning information, winning website, address for receiving the prize, etc.</div>
      </div>
      <div style="margin-top: 5px" class="row-left">
        <div style="line-height: 30px;" >·</div>
        <div class="content" style="margin-left: 5px">All activity information is subject to the announcement on the official website and the official announcement in the community.</div>
      </div>
      <div style="margin-top: 5px" class="row-left">
        <div style="line-height: 30px;" >·</div>
        <div class="content" style="margin-left: 5px">Does not trust any unofficial offline transactions, please verify the identity of the other party before offline transactions to avoid being deceived. </div>
      </div>
      <div style="margin-top: 5px" class="row-left">
        <div style="line-height: 30px;" >·</div>
        <div  class="content" style="margin-left: 5px">Do not disclose account information to anyone else for any reason or in any way</div>
      </div>
    </div>

    <el-dialog
      title="How to check UID?"
      v-model="dialogVisible"
      width="350px"
      >
      <div class="content">
        It’s shown in the side drawer in homepage.
      </div>
      <div class="content">
        Click to copy it and paste here.
      </div>
     <img style="width: 320px;margin-top: 20px" src="@/assets/image_tip.png">
    </el-dialog>

  </div>


</template>

<script>

  import axios from 'axios'
  import qs from 'qs'

const PAYMAXCT=['AE',
  'BH',
  'ID',
  'JO',
  'KW',
  'MY',
  'OM',
  'QA',
  'SA',
  'SG',
  'TH',
  'KR',
  'VN',
  'MA' ]
const PAYMENTWAY=['payermax','paymentwall']
export default {
  name: 'PurchaseView',
  components: {

  },
  data() {
    return {
      loading:false,
      user:{
        id:'123213123213',
        name:'Frank Fang',
        avatar:'https://pinkverse.oss-cn-hangzhou.aliyuncs.com/thumb/avatar/Joyce.png',
        diamond:100
      },
      uid:'',
      money:0,
      dialogVisible:false,
      productId:'',
      payDisable:true,
      payDevice:'',

      paymentWay:PAYMENTWAY[1]
    }
  },
  methods:{
    paySelect(num){
      this.money=num;
      console.log("money ",this.money)
      if(num==1){
        this.productId='pg_1001';
      }else if(num==5){
        this.productId='pg_1002';
      } else if(num==20){
        this.productId='pg_1003';
      } else if(num==50){
        this.productId='pg_1004';
      } else if(num==100){
        this.productId='pg_1005';
      } else if(num==250){
        this.productId='pg_1006';
      }
      if(this.uid&&this.uid.length>0){
        this.payDisable=false;
      }
    },
    choicePayway(){
      console.log("choicePayway paymentWay:",this.paymentWay)
      axios({
        url:'https://ipapi.co/country/',
        method:'get',
      }).then(res=>{
        let country=res.data;
        console.log("country:",country)
        if(PAYMAXCT.indexOf(country)>-1){
          this.paymentWay=PAYMENTWAY[0];
        }else {
          this.paymentWay=PAYMENTWAY[1];
        }
        console.log("paymentWay:",this.paymentWay)
      })


    },
    createOrder(){
      if(this.uid===null||this.uid.length<=0){
        this.$message.error('Please enter your UID');
        return;
      }
      if(this.money===null||this.money<=0){
        this.$message.error('Please select purchase diamonds');
        return;
      }
      if(this.loading){
        return ;
      }
      this.loading=true;
      axios({
        url:'/'+this.paymentWay,
        method:'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data:{
          uuid: this.uid,//1652157347513997
          payType: this.paymentWay,
          payDevice: this.payDevice,
          channelType: "webServer",
          productId: this.productId,
          buyType: "Wallet Deposit",
          frontCallbackURL: "https://buy.pinkverse.app/callback/"+this.paymentWay,
          successUrl: "https://buy.pinkverse.app/callback/"+this.paymentWay+"?status=success&transaction_id=$ref",
          failureUrl: "https://buy.pinkverse.app/callback/"+this.paymentWay+"?status=failure&transaction_id=$ref",
          price: this.money
        }

      }).then(res=>{
        console.log('createOrder res=>',res);
        if(res.status===200&&res.data.isOk){
//          window.onpageshow = null;
//          let _this=this;
//          window.onpageshow = function(event){
//            _this.goBack()
//          }
          window.location.href=res.data.data.redirectUrl
        }else{
          this.$message.error('Please check your  UID');
        }
        this.loading=false;
      })
    },
    goBack(){
      console.log("goBack!")
      this.money=0,
      this.loading=false;
  }
  },
  activated () {
     console.log("activated!")

  },

  mounted(){
    this.choicePayway();

  },
  created(){
    const device = navigator.userAgent
    if (device.indexOf('iPad') > -1) {
      this.payDevice='iPad';
    } else if (device.indexOf('Android') > -1) {
      this.payDevice='Android';
    } else if (device.indexOf('ios') > -1){
      this.payDevice='ios';
    }else {
      this.payDevice='PC';
    }
  }

}
</script>
<style scoped>

  .price{
    width: 100%;
    text-align: center;
    margin-top: 15px;
    color: #333333;
    opacity: 0.5;
  }
  .price-icon{
    width: 25px;height: 25px
  }
  .price-tx{
    font-size: 18px;font-weight: bold;margin-left: 5px
  }

  .purch-body{
    width: 100px;
    height: 80px;
    border-radius:5px;border:2px solid gainsboro;
    cursor: pointer;
  }
  .purch-body:hover{
    border-radius:5px;border:2px solid #FF9BC9;
  }
  .purch-body:focus{
    border-radius:5px;border:2px solid #FF9BC9;
  }
  .bottom {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
  }
  .bottom_img{
    width: 20px;
    height: 20px;
  }
  .bottom_tx{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #FF9BC9;
    padding-left: 10px;
  }

  .margin{
    margin-top: 20px;
  }
  .image {
    width: 30px;
    height: 30px;
    margin-right:10px;
  }
  .title{
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    /* identical to box height */
    color: rgba(0, 0, 0, 0.8);
  }
  .title-myacount{
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 42px;
    color: rgba(0, 0, 0, 0.8);
  }
  .content{
    color: #333333;
    opacity: 0.5;
    line-height: 30px;
  }
</style>
