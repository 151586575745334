<template>
    <div id="app" class="row-center-center" >

      <div style=" width:400px;">
        <el-container >
          <el-header style="position: fixed;background-color: white;width:400px;z-index: 1000">
            <header-component style="margin-top: 10px"></header-component>
            <div style="width: 400px;height: 20px;background-color: white"></div>
          </el-header>
          <el-main style="margin-top: 60px">
            <div class="row-center-center">
              <div class="router_body">
                <router-view />
              </div>
            </div>
          </el-main>
        </el-container>
      </div>
    </div>

</template>

<script>
  import HeaderComponent from "@/components/HeaderComponent.vue"
export default {
  name: 'App',
  components: {HeaderComponent}
}
</script>

<style scoped lang="scss">
  ::v-deep .el-container{height:100%;padding:0;margin:0;width:100%;}
</style>
<style>
#app,body{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0;
  /*外部间距也是如此设置*/
  margin: 0;
  /*统一设置高度为100%*/
  height: 100%;
  width: 100%;
}
.row-left {
  display: flex;
  flex-direction: row;
}
.row-left-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row-center-center {
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
  align-items: center;
}

.row-column-center {
  display: flex;
  justify-content:center;
  flex-direction:column;
  align-items: center;
}

.row-left-between {
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.row-top-between {
  display: flex;
  justify-content:space-between;
}

</style>
